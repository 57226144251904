@import './include-media';

$breakpoints: (small: 500, medium: 900, large: 1200, xlarge: 1400);

.cards {
  background-color: #000;
  padding: 20px;
    box-sizing: border-box;
  ul {
    
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    @include media('<1400px') {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @include media('<1200px') {
      //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @include media('<1000px') {
      //grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
    @include media('<800px') {
      grid-template-columns: 1fr 1fr 1fr ;
      .name-plate {
        font-size: 0.75rem;
      }
    }
    gap: 5px;
    .image-frame {
      cursor: pointer;
      width: 100%;
      height: 0;
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      &:after {
        content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    box-shadow: inset 0 0 15px 0px #000;
      }
      
    }
    .name-plate {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 0.9rem;
      color: #ccc;
      text-align: center;
      text-transform: uppercase;
      padding: 0.5rem;
      box-sizing: border-box;
background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      font-family: 'Lexend Tera', sans-serif;
      text-shadow: 0px -1px 4px black;
      @include media('<1000px') {
        padding: 0.25rem;
        font-size: 0.8rem;
      }
      @include media('<800px') {
        font-size: 0.75rem;
      }
    }
    img {
      width: 135%;
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.25;
    transition: filter 0.25s ease-in, opacity 0.25s ease-in;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    
    filter: grayscale(100%);
    }
    .image-frame {
      &.selected {
        img {
          opacity: 1;
          -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    
          filter: grayscale(0%);
        }
      }
    }
    li {
      font-size: 0;
    }
  }
}